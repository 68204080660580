define("discourse/plugins/discourse-breadcrumbify-topic-title-plugin/discourse/connectors/topic-title-suffix/breadcrumbified-topic-title", ["exports", "@glimmer/component", "discourse/lib/url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _url, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _BreadcrumbifiedTopicTitle;
  class BreadcrumbifiedTopicTitle extends _component.default {
    get title() {
      return this.args.outletArgs.model.title;
    }
    get model() {
      return this.args.outletArgs.model;
    }
    get isBeforeLastBreadcrumb() {
      return index1 => index1 === this.model.breadcrumbs.length - 2;
    }
    get isLastBreadcrumb() {
      return index1 => index1 === this.model.breadcrumbs.length - 1;
    }
  }
  _exports.default = BreadcrumbifiedTopicTitle;
  _BreadcrumbifiedTopicTitle = BreadcrumbifiedTopicTitle;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="breadcrumbified-topic-title">
        {{!-- {{this.title}}
          {{!-- {{log this.model.breadcrumbs}} --}}
  
        {{#each this.model.breadcrumbs as |breadcrumb index|}}
          {{#unless (this.isLastBreadcrumb index)}}
            {{#if breadcrumb.topic_id}}
              <a
                class="breadcrumb"
                href="/t/{{breadcrumb.topic_id}}"
              >{{breadcrumb.title}}</a>
            {{else}}
              <span class="breadcrumb">{{breadcrumb.title}}</span>
            {{/if}}
            {{#unless (this.isBeforeLastBreadcrumb index)}}
              <span class="separator"> &gt; </span>
            {{/unless}}
          {{/unless}}
          {{#if (this.isLastBreadcrumb index)}}
            <div class="title">{{breadcrumb.title}}</div>
          {{/if}}
        {{/each}}
      </div>
    
  */
  {
    "id": "PxO7ig5S",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"breadcrumbified-topic-title\"],[12],[1,\"\\n\"],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"model\",\"breadcrumbs\"]]],null]],null],null,[[[41,[51,[28,[30,0,[\"isLastBreadcrumb\"]],[[30,2]],null]],[[[41,[30,1,[\"topic_id\"]],[[[1,\"            \"],[10,3],[14,0,\"breadcrumb\"],[15,6,[29,[\"/t/\",[30,1,[\"topic_id\"]]]]],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,1],[14,0,\"breadcrumb\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"]],[]]],[41,[51,[28,[30,0,[\"isBeforeLastBreadcrumb\"]],[[30,2]],null]],[[[1,\"            \"],[10,1],[14,0,\"separator\"],[12],[1,\" > \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[41,[28,[30,0,[\"isLastBreadcrumb\"]],[[30,2]],null],[[[1,\"          \"],[10,0],[14,0,\"title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"]],[]],null]],[1,2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"breadcrumb\",\"index\"],false,[\"each\",\"-track-array\",\"unless\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-breadcrumbify-topic-title-plugin/discourse/connectors/topic-title-suffix/breadcrumbified-topic-title.js",
    "isStrictMode": true
  }), _BreadcrumbifiedTopicTitle);
});