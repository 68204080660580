define("discourse/plugins/discourse-breadcrumbify-topic-title-plugin/discourse/connectors/topic-title/topic-children", ["exports", "@glimmer/component", "@ember/service", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _BreadcrumbifiedTopicTitle;
  class BreadcrumbifiedTopicTitle extends _component.default {
    get children() {
      return this.model.children;
    }
    get model() {
      return this.args.outletArgs.model;
    }
    get childrenPresent() {
      return this.model.children.length > 0;
    }
    hasUpdates(topic1) {
      // Convert the timestamp strings to Date objects
      const updatedDate1 = new Date(topic1.updated_at);
      const lastVisitedDate1 = topic1.last_visited_at ? new Date(topic1.last_visited_at) : undefined;
      // If lastVisitedDate is undefined, return true
      if (!lastVisitedDate1) {
        return "has-updates";
      }
      // Compare the two dates
      return updatedDate1 > lastVisitedDate1 ? "has-updates" : "";
    }
    cleanTitle(title1) {
      // Split the input string by the '>' symbol
      const segments1 = title1.split(">");
      // Trim any leading or trailing whitespace from each segment
      const trimmedSegments1 = segments1.map(segment1 => segment1.trim());
      // Return the last segment
      return trimmedSegments1[trimmedSegments1.length - 1];
    }
  }
  _exports.default = BreadcrumbifiedTopicTitle;
  _BreadcrumbifiedTopicTitle = BreadcrumbifiedTopicTitle;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.childrenPresent}}
        <ul class="topic-children">
          {{#each this.children as |child index|}}
            <li
              class="{{if child.bookmarked 'bookmarked'}}
                {{this.hasUpdates child}}"
            >
              <a class="topic-child-link" href="/t/{{child.slug}}/{{child.id}}">
                {{this.cleanTitle child.title}}
                {{#if child.bookmarked}}
                  <svg
                    class="fa d-icon d-icon-bookmark svg-icon svg-string"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <use href="#bookmark"></use>
                  </svg>
                {{/if}}
              </a>
            </li>
          {{/each}}
        </ul>
      {{/if}}
    
  */
  {
    "id": "AwvuzaMD",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"childrenPresent\"]],[[[1,\"      \"],[10,\"ul\"],[14,0,\"topic-children\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"children\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[15,0,[29,[[52,[30,1,[\"bookmarked\"]],\"bookmarked\"],\"\\n              \",[28,[30,0,[\"hasUpdates\"]],[[30,1]],null]]]],[12],[1,\"\\n            \"],[10,3],[14,0,\"topic-child-link\"],[15,6,[29,[\"/t/\",[30,1,[\"slug\"]],\"/\",[30,1,[\"id\"]]]]],[12],[1,\"\\n              \"],[1,[28,[30,0,[\"cleanTitle\"]],[[30,1,[\"title\"]]],null]],[1,\"\\n\"],[41,[30,1,[\"bookmarked\"]],[[[1,\"                \"],[10,\"svg\"],[14,0,\"fa d-icon d-icon-bookmark svg-icon svg-string\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n                  \"],[10,\"use\"],[14,6,\"#bookmark\"],[12],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"child\",\"index\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-breadcrumbify-topic-title-plugin/discourse/connectors/topic-title/topic-children.js",
    "isStrictMode": true
  }), _BreadcrumbifiedTopicTitle);
});